/** @jsxImportSource @emotion/react */
import React, { FC } from "react";
import Map, { Marker } from "react-map-gl";// eslint-disable-line import/no-webpack-loader-syntax
import { Loader } from "../../MWFramework/MWLoader/Loader";

interface IPropsMapContainer { }

interface IScoutingProps {
    long: string;
    lat: string;
    name: string;
    accomodation: string;
    street: string;
    housenumber: number;
    housenumber_addition: string | null;
    postcode: string;
    city: string;
    photo_assignment_points: number | null;
    area: string;


}

const MapContainer: FC<IPropsMapContainer> = () => {
    const TOKEN = "pk.eyJ1IjoibWFya2tpZWUiLCJhIjoiY2t1eDY5YXYyMmMwbTJ1cXY5c3hnaXkyaiJ9.xTAMNWP3gouVEQNTaX5EqQ";

    const [markers, setMarkers] = React.useState<IScoutingProps[]>([
        {
            "name": "Scouting Bemmel",
            "accomodation": "Blokhut",
            "street": "Sportlaan",
            "housenumber": 1,
            "housenumber_addition": "F",
            "postcode": "6681CD",
            "city": "Bemmel",
            "lat": "51.88719",
            "long": "5.90544",
            "photo_assignment_points": 0,
            "area": "Delta"
        },
        {
            "name": "Sw3lbp",
            "accomodation": "Blokhut",
            "street": "Jachtlaan",
            "housenumber": 4,
            "housenumber_addition": "-6",
            "postcode": "7313CP",
            "city": "Apeldoorn",
            "lat": "52.22582",
            "long": "5.94261",
            "photo_assignment_points": 0,
            "area": "Bravo"
        },
        {
            "name": "Tarcisius Ede",
            "accomodation": "Clubgebouw",
            "street": "Bosrand",
            "housenumber": 17,
            "housenumber_addition": "A",
            "postcode": "6718ZN",
            "city": "Ede",
            "lat": "52.05131",
            "long": "5.67849",
            "photo_assignment_points": 0,
            "area": "Echo"
        },
        {
            "name": "Scouting Keizer Karel NIJMEGEN",
            "accomodation": "Blokhut",
            "street": "Sophiaweg",
            "housenumber": 450,
            "housenumber_addition": null,
            "postcode": "6523 NJ",
            "city": "NIJMEGEN",
            "lat": "51.82516",
            "long": "5.89007",
            "photo_assignment_points": 0,
            "area": "Delta"
        },
        {
            "name": "Verbraak Margriet Groep",
            "accomodation": "Clubhuis",
            "street": "Strokelweg",
            "housenumber": 6,
            "housenumber_addition": null,
            "postcode": "3847 LR",
            "city": "Harderwijk",
            "lat": "52.32483",
            "long": "5.63567",
            "photo_assignment_points": 0,
            "area": "Alpha"
        },
        {
            "name": "Scouting Roothaangroep",
            "accomodation": "Clubgebouw",
            "street": "Koekendaalseweg",
            "housenumber": 11,
            "housenumber_addition": null,
            "postcode": "7004 GH",
            "city": "Doetinchem",
            "lat": "51.94916",
            "long": "6.32094",
            "photo_assignment_points": 0,
            "area": "Charlie"
        },
        {
            "name": "Scouting Valburg",
            "accomodation": "blokhut",
            "street": "Reethsestraat",
            "housenumber": 25,
            "housenumber_addition": null,
            "postcode": "6675 CE",
            "city": "Valburg",
            "lat": "51.90751",
            "long": "5.79430",
            "photo_assignment_points": 0,
            "area": "Delta"
        },
        {
            "name": "Scouting Didam",
            "accomodation": "Gebouw",
            "street": "Julianastraat",
            "housenumber": 41,
            "housenumber_addition": null,
            "postcode": "6942BB",
            "city": "Didam",
            "lat": "51.93854",
            "long": "6.13220",
            "photo_assignment_points": null,
            "area": "Charlie"
        },
        {
            "name": "Alexandergroep Ermelo",
            "accomodation": "Clubhuis",
            "street": "Watervalweg",
            "housenumber": 175,
            "housenumber_addition": null,
            "postcode": "3853 PS",
            "city": "Ermelo",
            "lat": "52.28673",
            "long": "5.59472",
            "photo_assignment_points": 0,
            "area": "Alpha"
        },
        {
            "name": "Graaf Otto Groep Lochem",
            "accomodation": "Scoutinggebouw",
            "street": "Gageldijk",
            "housenumber": 2,
            "housenumber_addition": "a",
            "postcode": "7241RJ",
            "city": "Lochem",
            "lat": "52.14482",
            "long": "6.39402",
            "photo_assignment_points": 0,
            "area": "Bravo"
        },
        {
            "name": "Graaf van Gelre Geldermalsen",
            "accomodation": "Clubgebouw",
            "street": "Burgemeester Roozeveld van der Venlaan",
            "housenumber": 13,
            "housenumber_addition": "A",
            "postcode": "4191PL",
            "city": "Geldermalsen",
            "lat": "51.87771",
            "long": "5.27115",
            "photo_assignment_points": 0,
            "area": "Foxtrot"
        },
        {
            "name": "Scouting Paul Kruger",
            "accomodation": "Blokhut",
            "street": "Sophiaweg",
            "housenumber": 448,
            "housenumber_addition": null,
            "postcode": "6523NJ",
            "city": "Nijmegen",
            "lat": "51.82578",
            "long": "5.89091",
            "photo_assignment_points": 0,
            "area": "Delta"
        },
        {
            "name": "Scouting JJB",
            "accomodation": "Blokhut",
            "street": "Berghuizerweg",
            "housenumber": 155,
            "housenumber_addition": null,
            "postcode": "7312 SN",
            "city": "Apeldoorn",
            "lat": "52.19646",
            "long": "5.92275",
            "photo_assignment_points": 0,
            "area": "Bravo"
        },
        {
            "name": "Captain Henzen",
            "accomodation": "Clubhuis",
            "street": "Reumersweg",
            "housenumber": 27,
            "housenumber_addition": null,
            "postcode": "3912AH",
            "city": "Rhenen",
            "lat": "51.96851",
            "long": "5.56084",
            "photo_assignment_points": 0,
            "area": "Echo"
        },
        {
            "name": "Rhedense Pioniers",
            "accomodation": "Blokhut",
            "street": "Brinkweg",
            "housenumber": 1,
            "housenumber_addition": "a",
            "postcode": "6991JN",
            "city": "Rheden",
            "lat": "52.00110",
            "long": "6.02038",
            "photo_assignment_points": 0,
            "area": "Charlie"
        },
        {
            "name": "Scouting IJsselgroep / Witte Wieven",
            "accomodation": "Clubhuis",
            "street": "Kwekerijweg",
            "housenumber": 5,
            "housenumber_addition": null,
            "postcode": "7213AX",
            "city": "Gorssel",
            "lat": "52.19986",
            "long": "6.21516",
            "photo_assignment_points": 0,
            "area": "Bravo"
        },
        {
            "name": "Scouting Saksenheerd",
            "accomodation": "Blokhut",
            "street": "Terborgseweg",
            "housenumber": 16,
            "housenumber_addition": "B",
            "postcode": "7084AJ",
            "city": "Breedenbroek",
            "lat": "51.87431",
            "long": "6.46377",
            "photo_assignment_points": 0,
            "area": "Charlie"
        },
        {
            "name": "Scouting Titus Brandsma",
            "accomodation": "Blokhut",
            "street": "Doornenburg 530, 7423 BS Deventer-Colmschate",
            "housenumber": 530,
            "housenumber_addition": null,
            "postcode": "7423BS",
            "city": "Deventer",
            "lat": "52.25057",
            "long": "6.21259",
            "photo_assignment_points": 0,
            "area": "Bravo"
        },
        {
            "name": "Velpsche Woudloopers",
            "accomodation": "blokhut",
            "street": "Beekhuizenseweg",
            "housenumber": 73,
            "housenumber_addition": "A",
            "postcode": "6881AE",
            "city": "Velp",
            "lat": "52.00824",
            "long": "5.97927",
            "photo_assignment_points": 0,
            "area": "Bravo"
        },
        {
            "name": "Karmijngroep Winssen",
            "accomodation": "Blokhut",
            "street": "Kennedysingel",
            "housenumber": 8,
            "housenumber_addition": "a",
            "postcode": "6645 AW",
            "city": "Winssen",
            "lat": "51.88145",
            "long": "5.70039",
            "photo_assignment_points": 0,
            "area": "Foxtrot"
        },
        {
            "name": "Woeste Weurtse Zeerovers",
            "accomodation": "Blokhut",
            "street": "Kerkstraat",
            "housenumber": 50,
            "housenumber_addition": null,
            "postcode": "6551ZZ",
            "city": "Weurt",
            "lat": "51.85915",
            "long": "5.81718",
            "photo_assignment_points": 0,
            "area": "Foxtrot"
        },
        {
            "name": "Scouting Amalgama",
            "accomodation": "Clubgebouw",
            "street": "Weteringweg",
            "housenumber": 50,
            "housenumber_addition": null,
            "postcode": "6546JZ",
            "city": "Nijmegen",
            "lat": "51.83934",
            "long": "5.78556",
            "photo_assignment_points": 0,
            "area": "Foxtrot"
        },
        {
            "name": "Subliem Hunting Team",
            "accomodation": "troephuis",
            "street": "slenterweg",
            "housenumber": 29,
            "housenumber_addition": null,
            "postcode": "6905DP",
            "city": "Zevenaar",
            "lat": "51.91567",
            "long": "6.06615",
            "photo_assignment_points": 0,
            "area": "Charlie"
        },
        {
            "name": "Scouting Jan Wandelaar",
            "accomodation": "Clubgebouw de Bosschuur",
            "street": "Dasselaarweg",
            "housenumber": 49,
            "housenumber_addition": null,
            "postcode": "3896 LT",
            "city": "Zeewolde",
            "lat": "52.30676",
            "long": "5.52886",
            "photo_assignment_points": 0,
            "area": "Alpha"
        },
        {
            "name": "Scouting Woezik",
            "accomodation": "blokhut",
            "street": "Oud Woeziksestraat",
            "housenumber": 3,
            "housenumber_addition": null,
            "postcode": "6604 KD",
            "city": "Wijchen",
            "lat": "51.82518",
            "long": "5.71741",
            "photo_assignment_points": 0,
            "area": "Foxtrot"
        },
        {
            "name": "Scouting Zetten",
            "accomodation": "Blokhut",
            "street": "H. Piersonstraat",
            "housenumber": 21,
            "housenumber_addition": null,
            "postcode": "6671 CK",
            "city": "Zetten",
            "lat": "51.93251",
            "long": "5.70936",
            "photo_assignment_points": 0,
            "area": "Echo"
        },
        {
            "name": "Scouting Jan Hilgers",
            "accomodation": "gebouw",
            "street": "Dwarsweg",
            "housenumber": 1,
            "housenumber_addition": null,
            "postcode": "6715AT",
            "city": "Ede",
            "lat": "52.04173",
            "long": "5.61969",
            "photo_assignment_points": 0,
            "area": "Echo"
        },
        {
            "name": "Scouting De Paulus",
            "accomodation": "Scouting gebouw",
            "street": "de paulus",
            "housenumber": 1,
            "housenumber_addition": null,
            "postcode": "6621 cb",
            "city": "Dreumel",
            "lat": "51.84867",
            "long": "5.43237",
            "photo_assignment_points": 0,
            "area": "Foxtrot"
        },
        {
            "name": "Scouting Schutgraaf",
            "accomodation": "Blokhut",
            "street": "Hannesstraatje",
            "housenumber": 2,
            "housenumber_addition": null,
            "postcode": "6843NV",
            "city": "Arnhem",
            "lat": "51.96620",
            "long": "5.86969",
            "photo_assignment_points": 0,
            "area": "Delta"
        },
        {
            "name": "Scouting St. Franciscus",
            "accomodation": "Blokhut",
            "street": "Sint Jorispad",
            "housenumber": 39,
            "housenumber_addition": null,
            "postcode": "6603AM",
            "city": "Wijchen",
            "lat": "51.80571",
            "long": "5.74512",
            "photo_assignment_points": 0,
            "area": "Foxtrot"
        },
        {
            "name": "Hélène H. Wilkensgroep",
            "accomodation": "Blokhut",
            "street": "De Halmen",
            "housenumber": 1,
            "housenumber_addition": "a",
            "postcode": "7383BG",
            "city": "Voorst",
            "lat": "52.18209",
            "long": "6.13891",
            "photo_assignment_points": 0,
            "area": "Bravo"
        },
        {
            "name": "Scouting Langenberggroep Ede",
            "accomodation": "Blokhut",
            "street": "Buurtmeesterweg",
            "housenumber": 27,
            "housenumber_addition": null,
            "postcode": "6711HL",
            "city": "Ede",
            "lat": "52.04035",
            "long": "5.69080",
            "photo_assignment_points": 0,
            "area": "Echo"
        },
        {
            "name": "André de Thaye",
            "accomodation": "Blokhut",
            "street": "Voetuislaan",
            "housenumber": 41,
            "housenumber_addition": null,
            "postcode": "6828 TD",
            "city": "Arnhem",
            "lat": "51.98138",
            "long": "5.93388",
            "photo_assignment_points": 0,
            "area": "Delta"
        },
        {
            "name": "Sint Werenfridusgroep",
            "accomodation": "Blokhut/clubhuis",
            "street": "De pas",
            "housenumber": 4,
            "housenumber_addition": null,
            "postcode": "6662BK",
            "city": "Elst",
            "lat": "51.90651",
            "long": "5.84324",
            "photo_assignment_points": 0,
            "area": "Delta"
        },
        {
            "name": "Scouting Bennekom",
            "accomodation": "Clubhuis",
            "street": "Selterskampweg",
            "housenumber": 68,
            "housenumber_addition": null,
            "postcode": "6721 AV",
            "city": "Bennekom",
            "lat": "52.01003",
            "long": "5.68688",
            "photo_assignment_points": 0,
            "area": "Echo"
        },
        {
            "name": "Scouting DBR",
            "accomodation": "Blokhut",
            "street": "Koningstraat",
            "housenumber": 114,
            "housenumber_addition": null,
            "postcode": "6654AH",
            "city": "Afferden",
            "lat": "51.88214",
            "long": "5.61591",
            "photo_assignment_points": 0,
            "area": "Foxtrot"
        },
        {
            "name": "Scouting Groessen en Vrienden",
            "accomodation": "Blokhut",
            "street": "Sint Isidorusplein",
            "housenumber": 8,
            "housenumber_addition": null,
            "postcode": "6923BD",
            "city": "Groessen",
            "lat": "51.93191",
            "long": "6.03228",
            "photo_assignment_points": 0,
            "area": "Charlie"
        },
        {
            "name": "De Markesteen",
            "accomodation": "Blokhut",
            "street": "Bovenallee",
            "housenumber": 3,
            "housenumber_addition": null,
            "postcode": "6881 AJ",
            "city": "Velp",
            "lat": "52.01596",
            "long": "5.98431",
            "photo_assignment_points": 0,
            "area": "Bravo"
        },
        {
            "name": "Irmin-Taweb",
            "accomodation": "clubhuis",
            "street": "kalkoenweg",
            "housenumber": 23,
            "housenumber_addition": null,
            "postcode": "3851 sb",
            "city": "ermelo",
            "lat": "52.31544",
            "long": "5.62017",
            "photo_assignment_points": 0,
            "area": "Alpha"
        },
        {
            "name": "Captain Castor",
            "accomodation": "Clubhuis",
            "street": "Staddijk",
            "housenumber": 105,
            "housenumber_addition": null,
            "postcode": "6537TW",
            "city": "Nijmegen",
            "lat": "51.80903",
            "long": "5.78972",
            "photo_assignment_points": 0,
            "area": "Foxtrot"
        },
        {
            "name": "Scouting Doesburg",
            "accomodation": "Basisschool",
            "street": "Forsythiastraat",
            "housenumber": 1,
            "housenumber_addition": null,
            "postcode": "6982 BA",
            "city": "Doesburg",
            "lat": "52.00789",
            "long": "6.14622",
            "photo_assignment_points": 0,
            "area": "Charlie"
        },
        {
            "name": "Ragay Redoz",
            "accomodation": "Blokhut",
            "street": "Strokelweg",
            "housenumber": 4,
            "housenumber_addition": "Strokelweg 4",
            "postcode": "3847LR",
            "city": "Harderwijk",
            "lat": "52.32639",
            "long": "5.63404",
            "photo_assignment_points": 0,
            "area": "Alpha"
        },
        {
            "name": "Scouting Nunspeet - Bram IJzerman",
            "accomodation": "Clubhuis",
            "street": "Zandeboslaan",
            "housenumber": 80,
            "housenumber_addition": null,
            "postcode": "8072PP",
            "city": "Nunspeet",
            "lat": "52.37630",
            "long": "5.80665",
            "photo_assignment_points": 0,
            "area": "Alpha"
        },
        {
            "name": "St. Christoforus Lichtdraagsters",
            "accomodation": "Blokhut",
            "street": "monnikensteeg",
            "housenumber": 4,
            "housenumber_addition": null,
            "postcode": "6823 AB",
            "city": "Arnhem",
            "lat": "51.99605",
            "long": "5.93160",
            "photo_assignment_points": 0,
            "area": "Echo"
        },
        {
            "name": "Scouting Hamaland",
            "accomodation": "Clubhuis",
            "street": "Kepelseweg",
            "housenumber": 249,
            "housenumber_addition": "Keppelseweg",
            "postcode": "7008 BB",
            "city": "Doetinchem",
            "lat": "51.97642",
            "long": "6.26740",
            "photo_assignment_points": 0,
            "area": "Charlie"
        },
        {
            "name": "Scoutinggroep Lido '76",
            "accomodation": "Blokhut",
            "street": "Veerpolderstraat",
            "housenumber": 128,
            "housenumber_addition": "a",
            "postcode": "6833 BS",
            "city": "Arnhem",
            "lat": "51.96825",
            "long": "5.91747",
            "photo_assignment_points": 0,
            "area": "Delta"
        },
        {
            "name": "Scouting Beuningen '76",
            "accomodation": "Blokhut",
            "street": "Reekstraat",
            "housenumber": 13,
            "housenumber_addition": "A",
            "postcode": "6551 DR",
            "city": "Weurt",
            "lat": "51.85362",
            "long": "5.78230",
            "photo_assignment_points": 0,
            "area": "Foxtrot"
        },
        {
            "name": "Scouting Dannenburcht",
            "accomodation": "Blokhut",
            "street": "Kloosterlaan",
            "housenumber": 1,
            "housenumber_addition": "A",
            "postcode": "6851CA",
            "city": "Huissen",
            "lat": "51.93565",
            "long": "5.94224",
            "photo_assignment_points": 0,
            "area": "Delta"
        },
        {
            "name": "Kon-Tiki",
            "accomodation": "clubhuis",
            "street": "Garderenseweg",
            "housenumber": 144,
            "housenumber_addition": "b",
            "postcode": "3881 NC",
            "city": "Putten",
            "lat": "52.24451",
            "long": "5.63981",
            "photo_assignment_points": 0,
            "area": "Alpha"
        },
        {
            "name": "Graaf Jan uit Ulft",
            "accomodation": "Scoutinggebouw",
            "street": "Bongersstraat",
            "housenumber": 263,
            "housenumber_addition": null,
            "postcode": "7071DZ",
            "city": "Ulft",
            "lat": "51.88209",
            "long": "6.38743",
            "photo_assignment_points": 0,
            "area": "Charlie"
        },
        {
            "name": "Scouting Phoenix Tiel",
            "accomodation": "blokhut",
            "street": "De Hennepe",
            "housenumber": 199,
            "housenumber_addition": null,
            "postcode": "4003AD",
            "city": "Tiel",
            "lat": "51.89960",
            "long": "5.42598",
            "photo_assignment_points": 0,
            "area": "Foxtrot"
        },
        {
            "name": "Scouting Scherpenzeel e.o.",
            "accomodation": "Clubgebouw",
            "street": "De Breelaan",
            "housenumber": 5,
            "housenumber_addition": null,
            "postcode": "3925HX",
            "city": "Scherpenzeel",
            "lat": "52.08602",
            "long": "5.48715",
            "photo_assignment_points": 0,
            "area": "Echo"
        },
        {
            "name": "De Geuzen Arnhem",
            "accomodation": "Blokhut",
            "street": "Diependalseweg",
            "housenumber": 3,
            "housenumber_addition": null,
            "postcode": "6813GE",
            "city": "Arnhem",
            "lat": "51.98763",
            "long": "5.87620",
            "photo_assignment_points": 0,
            "area": "Echo"
        },
        {
            "name": "Ugchelse woudlopers gr2",
            "accomodation": "Blokhut",
            "street": "Hulkestein",
            "housenumber": 45,
            "housenumber_addition": null,
            "postcode": "7339aw",
            "city": "Ugchelen",
            "lat": "52.18148",
            "long": "5.94204",
            "photo_assignment_points": 0,
            "area": "Bravo"
        },
        {
            "name": "SCOGA & JONKO",
            "accomodation": "Clubgebouw",
            "street": "Hamminkstraat",
            "housenumber": 21,
            "housenumber_addition": null,
            "postcode": "7011HC",
            "city": "Gaanderen",
            "lat": "51.93797",
            "long": "6.34724",
            "photo_assignment_points": 0,
            "area": "Charlie"
        },
        {
            "name": "Karel de Stoute",
            "accomodation": "Blokhut",
            "street": "Weezenhof",
            "housenumber": 9311,
            "housenumber_addition": null,
            "postcode": "6536AE",
            "city": "Nijmegen",
            "lat": "51.80174",
            "long": "5.81787",
            "photo_assignment_points": 0,
            "area": "Delta"
        },
        {
            "name": "Scouting Pegasus",
            "accomodation": "Blokhut",
            "street": "De Beemd",
            "housenumber": 1,
            "housenumber_addition": null,
            "postcode": "6883 DA",
            "city": "Velp",
            "lat": "51.98718",
            "long": "5.99242",
            "photo_assignment_points": 0,
            "area": "Charlie"
        },
        {
            "name": "St. Stanislaus Culemborg",
            "accomodation": "Blokhut",
            "street": "Steenovenslaan",
            "housenumber": 20,
            "housenumber_addition": null,
            "postcode": "4101AM",
            "city": "Culemborg",
            "lat": "51.95987",
            "long": "5.21674",
            "photo_assignment_points": 0,
            "area": "Foxtrot"
        },
        {
            "name": "Scouting Duiven",
            "accomodation": "Blokhut",
            "street": "De nieuweling",
            "housenumber": 3,
            "housenumber_addition": null,
            "postcode": "6921 JL",
            "city": "Duiven",
            "lat": "51.94398",
            "long": "6.03232",
            "photo_assignment_points": 0,
            "area": "Charlie"
        },
        {
            "name": "Scouting Grave & Boxmeer",
            "accomodation": "Groot tuinhuisje",
            "street": "Overasseltseweg",
            "housenumber": 4,
            "housenumber_addition": "B",
            "postcode": "6582 BL",
            "city": "Heumen",
            "lat": "51.76530",
            "long": "5.82938",
            "photo_assignment_points": 0,
            "area": "Delta"
        },
        {
            "name": "Scoutinggroep Sint Joris",
            "accomodation": "Blokhut",
            "street": "Lindeboomweg",
            "housenumber": 6,
            "housenumber_addition": "b",
            "postcode": "7213 LS",
            "city": "Gorssel",
            "lat": "52.18742",
            "long": "6.22449",
            "photo_assignment_points": 0,
            "area": "Bravo"
        },
        {
            "name": "PPD en HVG Apeldoorn",
            "accomodation": "Scouting blokhut",
            "street": "Egerlaan",
            "housenumber": 7,
            "housenumber_addition": null,
            "postcode": "7334AD",
            "city": "Apeldoorn",
            "lat": "52.18493",
            "long": "5.96051",
            "photo_assignment_points": 0,
            "area": "Bravo"
        },
        {
            "name": "Scouting Lunteren",
            "accomodation": "Het Houten Hes",
            "street": "Hessenweg",
            "housenumber": 77,
            "housenumber_addition": null,
            "postcode": "6741JP",
            "city": "Lunteren",
            "lat": "52.09155",
            "long": "5.65104",
            "photo_assignment_points": 0,
            "area": "Echo"
        },
        {
            "name": "OPV-Schoonoord",
            "accomodation": "Blokhut",
            "street": "Van Borsselenweg",
            "housenumber": 43,
            "housenumber_addition": null,
            "postcode": "6862 BH",
            "city": "Oosterbeek",
            "lat": "51.98131",
            "long": "5.82139",
            "photo_assignment_points": 0,
            "area": "Echo"
        },
        {
            "name": "KaLiG",
            "accomodation": "Onderverdieping basisschool",
            "street": "Frans Halslaan",
            "housenumber": 101,
            "housenumber_addition": null,
            "postcode": "6814JP",
            "city": "Arnhem",
            "lat": "51.99229",
            "long": "5.89047",
            "photo_assignment_points": 0,
            "area": "Echo"
        },
        {
            "name": "Scouting \"de Woutergroep\" Deest",
            "accomodation": "Blokhut",
            "street": "Kweldam",
            "housenumber": 1,
            "housenumber_addition": "A",
            "postcode": "6653CB",
            "city": "Deest",
            "lat": "51.88902",
            "long": "5.66007",
            "photo_assignment_points": 0,
            "area": "Foxtrot"
        },
        {
            "name": "Gustaaf Adolf Groep",
            "accomodation": "Blokhutten",
            "street": "Oostloo",
            "housenumber": 8,
            "housenumber_addition": null,
            "postcode": "8084 PK",
            "city": "'t Harde",
            "lat": "52.40839",
            "long": "5.86412",
            "photo_assignment_points": 0,
            "area": "Alpha"
        },
        {
            "name": "Scouting Jan van Hoof",
            "accomodation": "Clubgebouw",
            "street": "Slotemaker de Bruineweg 161",
            "housenumber": 161,
            "housenumber_addition": null,
            "postcode": "6532AC",
            "city": "Nijmegen",
            "lat": "51.82276",
            "long": "5.84217",
            "photo_assignment_points": null,
            "area": "Delta"
        },
        {
            "name": "Klaas Kitten groep",
            "accomodation": "Scoutingclubhuis",
            "street": "Lonapark",
            "housenumber": 10,
            "housenumber_addition": null,
            "postcode": "7371 EH",
            "city": "Loenen",
            "lat": "52.12223",
            "long": "6.02802",
            "photo_assignment_points": 0,
            "area": "Bravo"
        },
        {
            "name": "Aerendheem",
            "accomodation": "Blokhut",
            "street": "Lindestraat",
            "housenumber": 26,
            "housenumber_addition": null,
            "postcode": "6823 JT",
            "city": "Arnhem",
            "lat": "52.00435",
            "long": "5.94784",
            "photo_assignment_points": 0,
            "area": "Echo"
        },
        {
            "name": "Scouting de Trijsberg",
            "accomodation": "gebouw",
            "street": "Oranje nassaulaan",
            "housenumber": 14,
            "housenumber_addition": null,
            "postcode": "8051PB",
            "city": "Hattem",
            "lat": "52.45698",
            "long": "6.04784",
            "photo_assignment_points": 0,
            "area": "Alpha"
        },
        {
            "name": "Jotihunt 2023 Orga",
            "accomodation": "HQ",
            "street": "Mr, Meester D.U. Stikkerstraat",
            "housenumber": 10,
            "housenumber_addition": null,
            "postcode": "6842 CW",
            "city": "Arnhem",
            "lat": "51.95362",
            "long": "5.87292",
            "photo_assignment_points": 0,
            "area": "Alpha"
        },
        {
            "name": "TEST",
            "accomodation": "TEST",
            "street": "TEST",
            "housenumber": 2,
            "housenumber_addition": null,
            "postcode": "4444aa",
            "city": "test",
            "lat": "51.99941",
            "long": "5.94149",
            "photo_assignment_points": null,
            "area": "Alpha"
        }
    ]);

    // const [markers, setMarkers] = useState([]);

    const getMarkerPicture = (marker: string) => {
        if (marker === "Alpha") {
            return "https://cdn.discordapp.com/attachments/617799651323805708/1029821079700656128/AlphaMarkerEcht.png"
        }
        if (marker === "Bravo") {
            return "https://cdn.discordapp.com/attachments/617799651323805708/1029821080166215680/BravoMarkerEcht.png"
        }
        if (marker === "Charlie") {
            return ""
        }
        if (marker === "Delta") {
            return ""
        }
        if (marker === "Echo") {
            return "https://cdn.discordapp.com/attachments/617799651323805708/1029822573502017546/EchoMarkerEcht.png"
        }
        if (marker === "Foxtrot") {
            return "https://cdn.discordapp.com/attachments/617799651323805708/1029822573061615627/FoxtrotMarkerEcht.png"
        }
    }

    return (
        <>
            {/* <Loader isLoading={true} /> */}
            <Map
                mapboxAccessToken={TOKEN}
                initialViewState={{
                    longitude: 5.686806944214183,
                    latitude: 52.010176977482494,
                    zoom: 11
                }}
                style={{ width: "100vw", height: "100vh" }}
                mapStyle="mapbox://styles/mapbox/streets-v9"
            >
                {markers.map((marker, index) => (
                    <Marker key={index} longitude={Number(marker.long)} latitude={Number(marker.lat)}>
                        <div
                            style={{
                                backgroundImage: 'url(' + getMarkerPicture(marker.area) + ')',
                                backgroundSize: 'cover',
                                color: 'white',
                                width: '21px',
                                height: '31px',
                                borderRadius: "50%",
                                cursor: "pointer"
                            }}
                            onClick={() => {
                                // Je kunt hier logica toevoegen voor wanneer er op de marker wordt geklikt.
                                console.log("Marker geklikt:", marker.name);
                            }}
                        ></div>
                    </Marker>

                ))}
            </Map >

        </>

    );
}
export default MapContainer;