
import { useState } from 'react';
import './App.css';
import { SidenavContext } from './Context/SideNavContext';
import { AppRoutes } from './Routes/Routes';
import SideNav from './Sidebar';
import MapContainer from './Components/Map';

function App() {
  const [isSidenavOpen, setIsSidenavOpen] = useState(true);
  const toggleSidenav = () => setIsSidenavOpen(!isSidenavOpen);
  return (
    <SidenavContext.Provider value={{ isSidenavOpen, toggleSidenav }}>
      <div>
        {/* <SideNav profileName="Jouw Naam" profileImage="https://picsum.photos/200" logoImage="https://picsum.photos/200" />
        <AppRoutes />; */}
        <MapContainer />
      </div>
    </SidenavContext.Provider >
  );
}

export default App;
